.App {
  text-align: center;
  font-family: 'Barlow', sans-serif;
}

.background{
  height:100%;
  width:100%;
  padding:10%;
  background-color: black;
  color:white;
  animation: flicker-load 1s ease-in;
}

#resume {
  padding: 0;
  overflow:hidden;
  overflow-x:hidden;
  overflow-y:hidden;
  height:100%;
  width:100%;
  position:absolute;
}

.headshot{
  border-radius: 50%;
  height: 300px;
  width:300px;
  border:3px solid white;
  padding: 1px;
  margin:1px;
}

.project{
  align-items: left;
  text-align: left;
  padding:10px;
}

.left-container {
  position: fixed;
}

.link{
  padding: 10px;
}
.link:hover{
  color:yellow;
}

.link:active{
  color:green;
}

/* For collapsible functionality */
@media (max-width:1200px) {
  .left-container {
      position: relative;
      width: 100%;
      height: auto;
      border-right: none;
      border-bottom: 1px solid #ccc;
  }
  .right-container {
      width: 100%;
      height: auto;
  }
}

.car{
  background-color: black;
  text-align: left;
  border: none;
}

h1{
  letter-spacing: .1em;
  text-shadow: 0 0 5px white,
  0 0 10px white,
  0 0 15px white,
  0 0 30px white,
  0 0 60px white;
  animation: flicker-load 1s ease-in;
  padding: 40px;
}
h2{
  text-shadow:
  0 0 10px white,
  0 0 15px white,
  0 0 30px white;
  padding: 20px;
}

@keyframes flicker-load{
  0%{
    text-shadow:None;
    color:black;
  }
 
  100%{
    color:white;
    text-shadow:
    0 0 10px white,
    0 0 15px white,
    0 0 30px white;
  }
}